@layer base {
    @font-face {
        font-family:"gentona";
        src:url('../fonts/Gentona-Light-4e1599bd15.eot');
        src:url('../fonts/Gentona-Light-4e1599bd15.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Gentona-Light-4d97d050a0.woff') format("woff"),
        url('../fonts/Gentona-Light-af85c2a5b7.ttf') format("truetype");
        font-weight:400;
        font-style:normal;
    }
   
    @font-face {
        font-family:"gentona";
        src:url('../fonts/Gentona-LightItalic-b8a63d93dd.eot');
        src:url('../fonts/Gentona-LightItalic-b8a63d93dd.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Gentona-LightItalic-319180e595.woff') format("woff"),
        url('../fonts/Gentona-LightItalic-df108df205.ttf') format("truetype");
        font-weight:400;
        font-style:italic;
    }

    @font-face {
        font-family:"gentona";
        src:url('../fonts/Gentona-SemiBold-9e92551376.eot');
        src:url('../fonts/Gentona-SemiBold-9e92551376.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Gentona-SemiBold-c264d90299.woff') format("woff"),
        url('../fonts/Gentona-SemiBold-4e978fb7c8.ttf') format("truetype");
        font-weight:600;
        font-style:normal;
    }
  
    @font-face {
        font-family:"gentona";
        src:url('../fonts/Gentona-SemiBoldItalic-7ce7fa5c9b.eot');
        src:url('../fonts/Gentona-SemiBoldItalic-7ce7fa5c9b.eot?#iefix') format("embedded-opentype"),
        url('../fonts/Gentona-SemiBoldItalic-7d8045647b.woff') format("woff"),
        url('../fonts/Gentona-SemiBoldItalic-4df375c597.ttf') format("truetype");
        font-weight:600;
        font-style:italic;
    }
    
    @font-face {
      font-family: 'Quadon';
      src: url('../fonts/quadon_medium.woff2') format('woff2'),
             url('../fonts/quadon_medium.woff') format('woff');
      font-weight: 400;
      font-style: normal; }

}